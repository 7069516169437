const fp = require('lodash/fp');

export const getFullNameWithTFL = fp.flow(
  fp.pick(['title', 'firstName', 'lastName']),
  fp.values,
  fp.join(' '),
  fp.trim,
);
export const getFullNameWithLF = fp.flow(
  fp.pick(['lastName', 'firstName']),
  fp.values,
  fp.join(', '),
);

export const getFullNameWithFL = fp.flow(
  fp.pick(['firstName', 'lastName']),
  fp.values,
  fp.join(', '),
);
export const getWorkplaceOrTFL = p => p.workplace || getFullNameWithTFL(p);

export const createInternalNameForUserTagName = fp.flow(
  fp.replace(/ä|ö|ü|ß/g, match => {
    switch (match) {
      case 'ä':
        return 'ae';
      case 'ö':
        return 'oe';
      case 'ü':
        return 'ue';
      case 'ß':
        return 's';
      default:
        return '';
    }
  }),
  fp.replace(/[^a-zA-Z0-9_]/g, ''),
  fp.snakeCase,
);
