import dayjs from './dayjs.utils';

export const maxAge = 120;

export const isValidDateOfBirth = (dateOfBirth: Date): boolean => {
  if (!dayjs(dateOfBirth).isValid()) {
    return false;
  }

  const currentDate = dayjs();
  const minDate = currentDate.subtract(maxAge, 'year');

  return dayjs(dateOfBirth).isAfter(minDate) && dayjs(dateOfBirth).isBefore(currentDate);
};

export const calculateAge = (dateOfBirth: Date) => {
  return dayjs().diff(dayjs(dateOfBirth), 'year');
};
