export * from './userTags.types';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}

export enum UserTypes {
  PHYSICIAN = 'physician',
  STAFF = 'staff',
  PATIENT = 'patient',
}

export enum BookmarkTypes {
  PRESCRIPTIONS = 'Prescriptions',
  INBOX_SEARCH_QUERY = 'InboxSearchQuery',
}

export enum ClientIDs {
  physician = 'medflex-web-physician',
  patient = 'medflex-web-patient',
  staff = 'medflex-web-staff',
}
