import { RequestTypes } from './pamRequest.types';

export enum UserTagKeys {
  APPOINTMENT = RequestTypes.APPOINTMENT,
  FINDINGS_INQUIRY = RequestTypes.FINDINGS_INQUIRY,
  GENERAL_INQUIRY = RequestTypes.GENERAL_INQUIRY,
  REFERRAL = RequestTypes.REFERRAL,
  REQUEST_FOLLOW_UP_PRESCRIPTION = RequestTypes.REQUEST_FOLLOW_UP_PRESCRIPTION,
  CONNECT_TO_PHYSICIAN = RequestTypes.CONNECT_TO_PHYSICIAN,
  PHONE = RequestTypes.PHONE,
  DOCUMENT = RequestTypes.DOCUMENT,
}

export enum UserTagTypes {
  SYSTEM = 'system',
  TAG = 'tag',
}

export type UserTag = {
  id: string;
  type: `${UserTagTypes}`;
  key: string;
  value: string;
  description: string;
  groupId: string;
};

export type UserTagGroup = {
  id: string;
  value: string;
};
