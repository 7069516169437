import { ConditionProperties } from 'json-rules-engine';
import { PhoneNumber } from './phoneNumber.types';

// eslint-disable-next-line import/prefer-default-export
export enum TaCallStatus {
  COMPLETED = 'COMPLETED',
  UNCOMPLETED = 'UNCOMPLETED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  BUSY = 'BUSY',
  NO_ANSWER = 'NO_ANSWER',
  FAILED = 'FAILED',
  RINGING = 'RINGING',
}

export enum TaStepTypes {
  GENERIC = 'GENERIC',
  LANGUAGE = 'LANGUAGE',
  IS_MOBILE = 'IS_MOBILE',
  KNOWN_USER = 'KNOWN_USER',
  GATHER = 'GATHER',
  RECORD = 'RECORD',
  TIME = 'TIME',
  DIAL = 'DIAL',
}

export enum TaConfigFileTypes {
  AUDIO = 'AUDIO',
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
}

export enum TaConfigStatus {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}

type TaTag = {
  id: string;
  value: string;
};

export interface TaStepFiles {
  file: string;
  language: string;
}

export type TaTranslation = {
  text: string;
  language?: string;
};

export type TaReplyOption = {
  Digits: string[];
  SpeechResult: string[];
  translations: TaTranslation[];
};

export enum TaTwilioResponseVerbs {
  SAY = 'say',
  PLAY = 'play',
  RECORD = 'record',
  GATHER = 'gather',
  REDIRECT = 'redirect',
  DIAL = 'dial',
  HANGUP = 'hangup',
}

export type TaTwilioResponseSetting = {
  response?: TaTwilioResponseSetting[];
  language?: string;
  verb?: TaTwilioResponseVerbs;
  attributes?: any;
  children?: string | TaTwilioResponseSetting[];
};

type TaStepSettingsReplyOptionsMappingItem = {
  key: string;
  value: any;
};

type TaStepData = {
  language?: string;
  meta?: {
    [k: string]: any;
  };
  tags: TaTag[];
};

export type TaStepSettings = {
  maxTries?: number;
  response?: TaTwilioResponseSetting[];
  wait?: boolean;
  language?: string;
  data?: TaStepData;
};

type TaStepMatchType = 'all' | 'any';

export type TaStepMatchProperties = ConditionProperties;

export type TaStepMatch = {
  [k in TaStepMatchType]?: ConditionProperties[];
};

type TaStepBase = {
  type:
    | TaStepTypes.TIME
    | TaStepTypes.DIAL
    | TaStepTypes.RECORD
    | TaStepTypes.KNOWN_USER
    | TaStepTypes.IS_MOBILE;
  settings: TaStepSettings;
};

type TaStepGeneric = {
  type: TaStepTypes.GENERIC;
  settings: TaStepSettings;
};

type TaStepGather = {
  type: TaStepTypes.GATHER;
  settings: TaStepSettings & {
    replyOptions?: TaReplyOption[];
    replyOptionsMapping?: TaStepSettingsReplyOptionsMappingItem[];
  };
};

type TaStepLanguage = {
  type: TaStepTypes.LANGUAGE;
  settings: TaStepSettings & {
    replyOptions?: TaReplyOption[];
    replyOptionsMapping?: string[];
  };
};

export type TaStep = {
  id: string;
  title: TaTranslation[];
  label?: TaTranslation[];
  match?: TaStepMatch;
  steps?: TaStep[];
} & (TaStepBase | TaStepGeneric | TaStepGather | TaStepLanguage);

export type TaConfigRevision = {
  id: string;
  steps: TaStep[];
  configId: string;
  practitionerId: string;
};

type TaConfigFileTextToSpeech = {
  type: TaConfigFileTypes.TEXT_TO_SPEECH;
  voice: string;
  text: string;
};

type TaConfigFileBase = {
  type: TaConfigFileTypes.AUDIO;
};

export type TaConfigFile = {
  id: string;
  key: string;
  name: string;
  mediaType: string;
  bytes: number;
} & (TaConfigFileBase | TaConfigFileTextToSpeech);

export type TaConfig = {
  id: string;
  title?: string;
  practitionerId: string;
  trunk: string;
  status: TaConfigStatus;
  revisionId: string;
  revision: TaConfigRevision;
  language: string;
  files?: TaConfigFile[];
  updatedAt: Date;
};

type TaCallStepHistoryItemGeneric = {
  stepType: TaStepTypes.GENERIC;
  data?: TaStepData;
};

type TaCallStepHistoryItemLanguage = {
  stepType: TaStepTypes.LANGUAGE;
  data?: TaStepData & {
    language: string;
  };
};

type TaCallStepHistoryItemTime = {
  stepType: TaStepTypes.TIME;
  data?: TaStepData & {
    day: number;
  };
};

type TaCallStepHistoryItemDial = {
  stepType: TaStepTypes.DIAL;
  data?: TaStepData & {
    DialCallStatus: string;
    DialCallDuration: string;
    DialCallSid: string;
    DialBridged: boolean;
    RecordingUrl?: string;
  };
};

type TaCallStepHistoryItemRecord = {
  stepType: TaStepTypes.RECORD;
  data?: TaStepData & {
    RecordingUrl: string;
    RecordingDuration: number;
    Digits: string;
    transcription?: string;
    translations?: TaTranslation[];
  };
};

type TaCallStepHistoryItemGather = {
  stepType: TaStepTypes.GATHER;
  data?: TaStepData & {
    Digits: string;
    SpeechResult: string;
  };
  replyOptions: TaReplyOption[];
};

type TaCallStepHistoryItemIsMobile = {
  stepType: TaStepTypes.IS_MOBILE;
  data?: TaStepData & {
    isMobile: boolean;
  };
};

type TaCallStepHistoryItemKnownUser = {
  stepType: TaStepTypes.KNOWN_USER;
  data?: TaStepData & {
    knownUser: boolean;
  };
};

export type TaCallStepHistoryItem = {
  id: string;
  stepId: string;
  title: TaTranslation[];
  label?: TaTranslation[];
  tries?: number;
} & (
  | TaCallStepHistoryItemGeneric
  | TaCallStepHistoryItemGather
  | TaCallStepHistoryItemRecord
  | TaCallStepHistoryItemIsMobile
  | TaCallStepHistoryItemKnownUser
  | TaCallStepHistoryItemTime
  | TaCallStepHistoryItemLanguage
  | TaCallStepHistoryItemDial
);

export type TaCall = {
  id: string;
  configId: string;
  sid: string;
  stepId: string;
  status: TaCallStatus;
  practitionerId: string;
  trunk: string;
  phoneNumber: PhoneNumber | null;
  language: string;
  stepsHistory: TaCallStepHistoryItem[];
  createdAt: Date;
};
